import { ElLoading, ElMessage } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import { mixins } from "@/plugins/mixins";
import bus from "@/plugins/bus";
export default {
  name: "bikeWhiteListAdd",
  mixins: [mixins],
  data() {
    return {
      customerTypeList: [],
      orgList: [],
      def: [new Date(2023, 1, 1, 0, 0, 0), new Date(2023, 2, 1, 23, 59, 59)],
      loading: false,
      options: [],
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'id',
        label: 'name',
        lazy: true,
        lazyLoad: (node, resolve) => {
          resolve([]);
        }
      },
      edit: false,
      formModel: {
        id: null,
        orgId: null,
        customerId: '',
        customerType: null,
        startTime: '',
        endTime: '',
        reason: null
      },
      formRules: {
        orgId: [{
          required: true,
          message: '请选择机构',
          trigger: 'blur'
        }],
        reason: [{
          required: true,
          message: '请输入原因',
          trigger: 'blur'
        }],
        customerId: [{
          required: true,
          message: '请选择用户',
          trigger: 'blur'
        }],
        customerType: [{
          required: true,
          message: '请选择用户类型',
          trigger: 'blur'
        }],
        dates: [{
          required: true,
          message: '请选择时间范围',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {},
  methods: {
    //查询模糊查询用户
    remoteMethod(query) {
      if (query && query.length > 2) {
        this.loading = true;
        this.$api.business.customer.getByPhone({
          phone: query
        }).then(res => {
          if (res.code === 200) {
            this.options = res.data;
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          var param = {
            id: this.formModel.id,
            orgId: this.formModel.orgId,
            customerId: this.formModel.customerId,
            customerType: this.formModel.customerType,
            startTime: this.formModel.dates[0],
            endTime: this.formModel.dates[1],
            reason: this.formModel.reason
          };
          if (this.edit) {
            this.$api.business.whiteList.update(param).then(callBack);
          } else {
            this.$api.business.whiteList.create(param).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    this.$api.business.dictionary.dictItems({
      categoryCode: 'customer_type'
    }).then(res => {
      if (res.code === 200) {
        this.customerTypeList = res.data;
      }
    });
    this.$api.uac.org.list().then(res => {
      if (res.code === 200) {
        this.orgList = res.data;
      }
    });
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.whiteList.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = Object.assign(this.formModel, res.data);
          this.formModel.dates = [this.formModel.startTime, this.formModel.endTime];
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};